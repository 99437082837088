import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import moment from 'moment'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.filter('formatDate', value => {
  if (value) {
    // .tz('Atlantic/Madeira')
    return moment(String(value)).format('DD/MM/YYYY hh:mm A')
  }
  return ''
})

Vue.filter('formatDateOnly', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
  return ''
})

Vue.filter('roundDecimal', value => {
  if (value) {
    return Math.round(value * 100) / 100
  }
  return ''
})
