import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import axios from '@/libs/axios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'dashboard',
        action: 'read',
      },
    },
    {
      path: '/shipment',
      name: 'shipment',
      component: () => import('@/views/shipments/Index.vue'),
      meta: {
        pageTitle: 'Shipments',
        breadcrumb: [
          {
            text: 'Shipments List',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'read',
      },
    },
    {
      path: '/shipment/create',
      name: 'shipment',
      component: () => import('@/views/shipments/Form.vue'),
      meta: {
        pageTitle: 'Shipments',
        breadcrumb: [
          {
            text: 'Create New Shipment',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'create',
      },
    },
    {
      path: '/shipment/:id/edit',
      name: 'shipment',
      component: () => import('@/views/shipments/Form.vue'),
      meta: {
        pageTitle: 'Shipments',
        breadcrumb: [
          {
            text: 'Edit Shipment',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'update',
      },
    },
    {
      path: '/shipment/uploadFile',
      name: 'shipment',
      component: () => import('@/views/shipments/UploadFile.vue'),
      meta: {
        pageTitle: 'Shipments',
        breadcrumb: [
          {
            text: 'Upload File',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'upload',
      },
    },
    {
      path: '/shipment/:id/show',
      name: 'shipment',
      component: () => import('@/views/shipments/Show.vue'),
      meta: {
        pageTitle: 'Shipment Details',
        breadcrumb: [
          {
            text: 'Show Details',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'read',
      },
    },
    {
      path: '/shipment/:id/miniPrint',
      name: 'shipment',
      component: () => import('@/views/shipments/ShipmentMiniPrint.vue'),
      meta: {
        pageTitle: 'Shipment Details',
        breadcrumb: [
          {
            text: 'Shipment Details',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'read',
      },
    },
    {
      path: '/shipment/miniPrint',
      name: 'shipment',
      component: () => import('@/views/shipments/ShipmentMini.vue'),
      meta: {
        pageTitle: 'Shipment Details',
        breadcrumb: [
          {
            text: 'Shipment Details',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'read',
      },
    },
    {
      path: '/shipment/print',
      name: 'shipment',
      component: () => import('@/views/shipments/ShipmentPrint.vue'),
      meta: {
        pageTitle: 'Shipment Print',
        breadcrumb: [
          {
            text: 'Print Page',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'read',
      },
    },
    {
      path: '/shipment/invoice',
      name: 'shipment',
      component: () => import('@/views/shipments/ShipmentInvoice.vue'),
      meta: {
        pageTitle: 'Shipment Invoice',
        breadcrumb: [
          {
            text: 'Print Page',
            active: true,
          },
        ],
        resource: 'shipment',
        action: 'read',
      },
    },
    {
      path: '/pickup',
      name: 'view_pickups',
      component: () => import('@/views/pickups/Index.vue'),
      meta: {
        pageTitle: 'Pickups',
        breadcrumb: [
          {
            text: 'Pickups List',
            active: true,
          },
        ],
        resource: 'pickup',
        action: 'read',
      },
    },
    {
      path: '/pickup/create',
      name: 'create_pickup',
      component: () => import('@/views/pickups/Form.vue'),
      meta: {
        pageTitle: 'Pickups',
        breadcrumb: [
          {
            text: 'Create New pickup',
            active: true,
          },
        ],
        resource: 'pickup',
        action: 'create',
      },
    },
    {
      path: '/pickup/:id/edit',
      name: 'edit_pickup',
      component: () => import('@/views/pickups/Form.vue'),
      meta: {
        pageTitle: 'Pickups',
        breadcrumb: [
          {
            text: 'Edit Pickup',
            active: true,
          },
        ],
        resource: 'pickup',
        action: 'update',
      },
    },
    {
      path: '/pickup/:id/show',
      name: 'show_pickup',
      component: () => import('@/views/pickups/Show.vue'),
      meta: {
        pageTitle: 'Pickups',
        breadcrumb: [
          {
            text: 'View Pickup',
            active: true,
          },
        ],
        resource: 'pickup',
        action: 'read',
      },
    },
    /* {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    }, */
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/accountSettings',
      name: 'accountSettings',
      component: () => import('@/views/AccountSettings'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: '',
            active: true,
          },
        ],
        resource: 'dashboard',
        action: 'read',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

if (isUserLoggedIn()) {
  axios.get('/settings').then(resp => {
    window.Settings = resp.data.data
    localStorage.setItem('settings', JSON.stringify(resp.data.data))
  })
}

router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.name !== 'login' && !isUserLoggedIn()) next({ name: 'login' })
  // else if (!canNavigate(to) && to.name !== 'error-404') next({name: 'error-404'})
  else next()
})

export default router
