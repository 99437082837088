import Vue from 'vue'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import JsonExcel from 'vue-json-excel'
// import axios from 'axios'
import Echo from 'laravel-echo'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.component('downloadExcel', JsonExcel)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

window.Pusher = require('pusher-js')

const token = localStorage.getItem('accessToken')

window.EchoInstance = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  wsHost: process.env.VUE_APP_WS_HOST,
  wsPort: 6001,
  wssPort: 6001,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  encrypted: true,
  forceTLS: false, // make it true if SSL domain
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  // authEndpoint: '/broadcasting/auth',
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
})

window.EchoInstance.connector.pusher.connection.bind('connected', event => {
  console.log(event)
})

new Vue({
  router,
  /* created() {
    axios.get('/settings').then(resp => {
      window.Settings = resp.data.data
      // console.log(window.Settings)
    })
  }, */
  store,
  render: h => h(App),
}).$mount('#app')
