import Vue from 'vue'

// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${process.env.VUE_APP_BACK_URL}/api/`,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    // Remove userData from localStorage
    localStorage.removeItem('userData')
    alert('Your session is expired, try login again')
    window.location.reload()
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
